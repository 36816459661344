
export default {
  emits: ['update:modelValue'],
  data() {
    return {
      deckInputMixin__internalValue: _cloneDeep(this.modelValue),
      deckInputMixin__initialInternalValue: _cloneDeep(this.modelValue),
    };
  },
  computed: {
    deckInputMixin__isEmpty() {
      return this.deckInputMixin__isEmptyValue(this.deckInputMixin__internalValue);
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler(modelValue) {
        if (_isEqual(modelValue, this.deckInputMixin__internalValue)) return;

        this.deckInputMixin__internalValue = _cloneDeep(modelValue);
        this.deckInputMixin__initialInternalValue = _cloneDeep(modelValue);
      },
    },
    deckInputMixin__internalValue(value) {
      if (value === this.modelValue) return;
      if (this.required && this.deckInputMixin__isEmptyValue(value)) return;

      /**
       * Emited whenever the value is changed.
       * @event input
       * @type {any}
       */
      this.$emit('update:modelValue', value);
    },
  },
  methods: {
    deckInputMixin__isEmptyValue(value) {
      // when value is a number, we can't use _isEmpty, because it will always return true
      if (typeof value === 'number') return (value === null || value === undefined);

      return _isEmpty(value);
    },
    deckInputMixin__resetInternalValue() {
      if (_isEqual(this.deckInputMixin__internalValue, this.deckInputMixin__initialInternalValue)) return;
      this.deckInputMixin__internalValue = _cloneDeep(this.deckInputMixin__initialInternalValue);
    },
  },
};
